import { collection, getDocs, getFirestore, query, orderBy, limit, addDoc } from "firebase/firestore";
import app from '../firebase';
import { Timestamp } from 'firebase/firestore';

const db = getFirestore(app);
const collectionName = "feedbacks";

export function convertTimestamp(timestamp) {
    if (timestamp === undefined) {
        return "";
    }

    let date = new Date(timestamp);
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    let yyyy = date.getFullYear();

    if (String(mm).length < 2) {
        mm = '0' + mm;
    }

    if (String(dd).length < 2) {
        dd = '0' + dd;
    }

    date = dd + '/' + mm + '/' + yyyy;
    return date;
}

export const getLastFeedback = async () => {
    try {
        const docRef = collection(db, collectionName);
        const queryParam = query(docRef, orderBy('timestamp', 'desc'), limit(5));
        const querySnapshot = await getDocs(queryParam);
        if (!querySnapshot.empty) {
            return querySnapshot.docs.map(doc => {
                return {
                    ...doc.data(),
                    id: doc.id,
                    timestamp: convertTimestamp(doc.data().timestamp),
                }
            });
        } else {
            return [];
        }
    } catch (error) {
        console.error(`Error getting documents:`, error);
        return [];
    }
}

export const getAllFeedback = async () => {
    try {
        const docRef = collection(db, collectionName);
        const queryParam = query(docRef, orderBy('timestamp', 'desc'));
        const querySnapshot = await getDocs(queryParam);
        if (!querySnapshot.empty) {
            return querySnapshot.docs.map(doc => {
                return {
                    ...doc.data(),
                    id: doc.id,
                    timestamp: convertTimestamp(doc.data().timestamp),
                }
            });
        } else {
            return [];
        }
    } catch (error) {
        console.error(`Error getting documents:`, error);
        return [];
    }
}

export const setNewFeedBack = async (userName, userEmail, userContent) => {
    try {
        const docRef = await addDoc(collection(db, collectionName), {
            name: userName,
            email: userEmail,
            content: userContent,
            timestamp: Timestamp.now()
        });
        return { status: "SUCCESS", docId: docRef.id };
    } catch (error) {
        console.error("Error adding document: ", error);
        return { status: "ERROR", error: error.message };
    }
};
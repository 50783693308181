import { AppBar, Stack, Drawer, IconButton, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Button } from '@mui/base';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

function Navbar() {

    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };
    const isSmallScreen = useMediaQuery('(max-width:700px)');
    const location = useLocation();
    const navbarClass = 'bar-stack';
    return (
        <div>
            <AppBar position='fixed' className={navbarClass} sx={{ backgroundColor: 'white' }}>
                <div
                    className='p-1 flex flex-row justify-between pr-4 pl-4 h-15'>
                    <div>
                        <Link to="/">
                            <div className='w-32' ></div>
                        </Link>
                    </div>
                    {!isSmallScreen && (
                        <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={64} style={{ backgroundColor: 'white', opacity: 1 }}>
                            <Button className="menu btn" href="/home" style={{ backgroundColor: 'white', opacity: 1 }}> Ataberk Musaoğlu </Button>
                            <>
                                <Button className="menu btn" href="/#/contact" style={{ backgroundColor: 'white', opacity: 1 }}> İletişim </Button>
                                <Button className="menu register" href="/#/test"> Testi Çöz </Button>
                            </>
                        </Stack>
                    )}
                    <div className="w-32" style={{ justifyContent: 'flex-end', display: 'flex', marginRight: '5px' }}>
                        {isSmallScreen && (
                            <IconButton
                                size="large"
                                edge="end"
                                color="#53565a"
                                aria-label="menu"
                                onClick={handleDrawerOpen}
                                className="navbar-btn"
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                    </div>

                    <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose} sx={{ width: '100%', backdropFilter: 'blur(5px)' }}>
                        {/*<div className="drawer-header">*/}
                        {/*    <IconButton onClick={handleDrawerClose}>*/}
                        {/*        <CloseIcon/>*/}
                        {/*    </IconButton>*/}
                        {/*</div>*/}

                        <Stack direction="column">
                            <Button className="menu responsive" href="/" onClick={handleDrawerClose}> Ana Sayfa </Button>
                            <Button className="menu responsive" href="/#/contact" onClick={handleDrawerClose}> İletişim </Button>
                            <Button className="menu responsive register" href="/#/test" onClick={handleDrawerClose}> Testi Çöz </Button>
                        </Stack>

                    </Drawer>


                </div>
            </AppBar>

        </div>
    )
}

export default Navbar;
export const studentsSentences = [
    {
        name: 'V.T.G.',
        content: 'Daha önce de okulumdan koçluk hizmeti almıştım ama çok etkili olmamıştı. Ataberkle tanıştığım günden beri bana hem akademik hem de arkadaşlık olarak çok şey kattı. Özellikle ayt matematikte çok sıkıntım vardı. Ataberk sayesinde netlerim düzenli bir şekilde arttı. Her şey için tekrardan teşekkür ederim',
    },
    {
        name: 'Y.İ.',
        content: 'Öncelikle sana çok teşekkür  ederim bu zorlu sınav sürecinde bana çok yardımcı oldun gerek kaynak ve çalışma yönlendirmelerin gerek  her moralimin düştüğü zaman yaptığın motivasyon konuşmalarınla hem bir Koç hem de bir arkadaş olduğun ve sınav sürecini daha iyi yönetmemi sağladığın için'
    },
    {
        name: 'A.A.',
        content: 'Rehberliğin sayesinde en doğru bölümü seçtim ve şu an bu kararımın ne kadar doğru olduğunu her gün yeniden anlıyorum sonsuz teşekkürler.',
    },
    {
        name: 'N.S.',
        content: 'Sayende, kendime en uygun bölümü buldum ve bu kararımın ne kadar doğru olduğunu her gün yeniden görüyorum. Teşekkür ederim!',
    },
    {
        name: 'İ.S.H.',
        content: 'Bana gösterdiğin sabır ve verdiğin değerli bilgiler sayesinde, kariyerim için en doğru tercihi yapabildim',
    },
    {
        name: 'M.E.K',
        content: 'Sayende, beni hem akademik hem de kişisel olarak tatmin eden bir bölümü tercih ettim. Minnettarım',
    }
];

export const opportunitySentences = [
    'En doğru tercih',
    'Meslek hayatını şekillendirecek yol haritası',
    'Çoğunluğu ODTÜ\'lü mentörlerden birebir destek',
];
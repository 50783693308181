import { GoogleGenerativeAI } from "@google/generative-ai";
// Initialize Generative AI client
const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GOOGLE_API_KEY); //When string key is passed, it worked. This version of the code is NOT working.
const model = genAI.getGenerativeModel({ model: "gemini-1.5-pro" });



export async function runGenerativeAI(answers, id) {
    if (!answers) return "No answers found.";

    const questions = [
        { id: 'question1', text: 'İdeal iş, kişisel yaşam tarzına ve hobilerine uyum sağlamalıdır.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question2', text: 'Teknolojiye olan yatkınlık, gelecekteki meslek seçimlerinde belirleyici olacaktır.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question3', text: 'Liderlik vasıfları doğuştan gelir ve sonradan geliştirilemez.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question4', text: 'Bir işin prestiji, toplumun o işe verdiği değerle ölçülmelidir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question5', text: 'Başarı, sadece bireysel çabalarla değil, aynı zamanda doğru mentorluk ve ağlarla da sağlanır.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question6', text: 'Karar verme sürecinde duygular, mantık kadar önemlidir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question7', text: 'İş seçiminde etik değerler, maaş ve avantajlardan daha önceliklidir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question8', text: 'Gelecekte, yaratıcı düşünme yeteneği teknik becerilerden daha değerli olacaktır.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question9', text: 'Sürdürülebilirlik ve çevreye duyarlılık, bir işin seçiminde kritik faktörlerdir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question10', text: 'Hayatta büyük riskler almak, büyük ödüller getirir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question11', text: 'Çalışma hayatında dengeyi bulmak için, zaman zaman fedakarlık yapmak zorunludur.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question12', text: 'Bir işi sevmek, o işte ustalaşmak için yeterli bir motivasyondur.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question13', text: 'Akademik başarı, iş hayatında uzun vadeli başarı için yeterli bir gösterge değildir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question14', text: 'İş tatmini, kariyer gelişimi ve yükselmeden daha önemlidir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question15', text: 'Hayatta mutlu olmak için, sürekli olarak konfor alanının dışına çıkmak gereklidir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question16', text: 'Kariyer seçiminde aile ve toplum baskısı, kişisel tercihlerin önüne geçebilir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question17', text: 'Küreselleşen dünyada, çok kültürlü bir çalışma ortamında bulunmak kritik bir beceridir.', context: 'Size en çok uyanı seçiniz' },
    ];

    const prompt = `
        Aşağıda bazı sorular bulunmaktadır:
        ${questions.map(q => `${q.text} Cevap: ${answers[q.id]}/4`).join('\n')}
      
        0/4 -> kesinlikle katılmıyorum.
        1/4 -> katılmıyorum.
        2/4 -> çekimserim.
        3/4 -> katılıyorum.
        4/4 -> kesinlikle katılıyorum.
        Olacak şekilde, yukarıda yks sınavı sonrası bölüm seçimi yapacak bir öğrenciye verilebilecek en iyi bölüm tavsiyesini ver, bunu yaparken gerçekçi bir yaklaşım tutun. Endüstriyel Tasarım diyeceğin zaman Endüstri Mühendisliğini de belirtebilirsin. Derin bir Bölüm önerisi versen yeterli, yandal - çift anadal gibi belirtme. 4 BÖLÜM ÖNER`;

    try {
        console.log("Prompt being sent to AI model:", prompt);
        const msg = `${prompt}`;
        const result = await model.generateContent(msg);
        console.log("Raw result from AI model:", JSON.stringify(result, null, 2));
        const response = result.response.candidates[0].content.parts[0].text;
        console.log("AI response:", response);
        return response;
    } catch (error) {
        console.error("Error generating AI response:", error);
        return "Error generating AI response.";
    }
}

import React from 'react';
import { Box, Button, Card, CardContent, Grid, Divider, Stack, useMediaQuery } from '@mui/material';
import { AccountCircle, TaskAlt, Phone  } from '@mui/icons-material';
import {studentsSentences, opportunitySentences} from './../../utils/constantTexts';
import HomeImage from './../../assets/home/home.png';
import './HomePage.css';

function Home() {
    const isSmallScreen = useMediaQuery('(max-width:700px)');

    return (
        <div className='home-page-div'>
            <Stack spacing={15}>
                <Stack direction={'column'} justifyContent="center" alignItems="center" spacing={1}>
                    <div className='main-title'>YKS tercihlerinde endişe yok, çünkü hocam ODTÜ'lü!</div>
                    <div className='main-subtitle'> AI Destekli Tercih Hizmeti</div>
                    <Stack direction={isSmallScreen? "column" : "row"}  alignItems= "center" justifyContent="center" spacing={5}>
                        <Button variant="outlined"
                            size="small"
                            style={{
                                color: 'black',
                                borderColor: '#53565a',
                                backgroundColor: 'white',
                                borderRadius: '30px',
                                borderWidth: '0.5px',
                                marginTop: '10px',
                                textTransform: 'none',
                            }}
                            startIcon={<Phone/>}
                            className="left-container-btn"
                            id="login-btn"
                            href='/#/contact'>
                            <div className='main-subtitle'> İletişim </div>
                        </Button>
                        <Button variant="outlined"
                            size="small"
                            style={{
                                color: '#ffffff',
                                borderColor: '#E03838A8',
                                backgroundColor: '#9e011b',
                                borderRadius: '30px',
                                borderWidth: '0.5px',
                                marginTop: '10px',
                                textTransform: 'none',
                            }}
                            startIcon={<AccountCircle />}
                            className="left-container-btn"
                            id="login-btn"
                            href='/#/test'>
                            <div className='main-subtitle'> Tercih Testini Çöz </div>
                        </Button>
                    </Stack>
                </Stack>
                <Divider color='#c8102e'/>
                <Stack direction='column' spacing={2} justifyContent='center' alignItems='center'>
                    <div className='main-title-1'> Tercih yükü altında ezildiğini mi hissediyorsun?</div>
                    <div className='main-subtitle'> AI Destekli Tercih Hizmeti</div>
                    <Stack direction={isSmallScreen ? 'column' : 'row'} justifyContent="center" alignItems="center" spacing={5}>
                        <img className='home-image' src={HomeImage} alt=''/>
                        <div>
                            {opportunitySentences.map(e => 
                            <div className='m-4'>
                                <Stack direction={'row'} alignItems="center" spacing={1}>
                                    <TaskAlt sx={{color: 'green'}}/>
                                    <div className='sentence'>{e}</div>
                                </Stack>
                            </div>
                            )}
                        </div>
                    </Stack>
                </Stack>
                <Divider color='#c8102e'/>
                <Stack justifyContent="center" alignItems="center" spacing={2}>
                    <div className='main-title-1'>Testi neden çözmelisin?</div>
                    <p className='main-subtitle'> 
                        Üniversite sınavına hazırlanan öğrenciler için yapay zeka destekli meslek testimiz hayati bir rol oynar. Bu test, kendinizi tanıma ve yeteneklerinizi keşfetme sürecinde önemli bir araçtır. Test sonuçları, ilgi alanlarınız ve değerlerinizle uyumlu meslekleri belirlemenize yardımcı olur. Örneğin, yazılım mühendisliğine yatkın olduğunuzu fark ederek, teknolojiye olan ilginizi bu alanda değerlendirebilirsiniz.
                        Yapay zeka destekli meslek testimiz, doğru bölüm seçimi yaparak eğitim hayatınızı daha verimli geçirmenizi sağlar. Ayrıca, bilinçli kariyer hedefleri belirlemenize ve bu yolda motivasyonunuzu yüksek tutmanıza yardımcı olur. Kendi potansiyelinizi ve güçlü yönlerinizi keşfettikçe, geleceğe daha güvenle bakabilir, kariyer planlarınızı sağlam temeller üzerine inşa edebilirsiniz.
                        Sonuç olarak, yapay zeka destekli meslek testimiz, sadece bir rehber değil, aynı zamanda kişisel ve profesyonel gelişiminiz için güçlü bir destek sağlar. Kendinizi tanıyın, geleceğinizi şekillendirin.
                    </p>
                </Stack>
                <Divider color='#c8102e'/>
                <Stack justifyContent="center" alignItems="center" spacing={2}>
                    <div className='main-title-1 '> Danışanlarımızın Yorumları</div>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={5}>
                        {
                            studentsSentences.map(e => 
                                <Grid item xs={isSmallScreen ? 12 : 4}>
                                <Card className='h-full justify-items-stretch'>
                                    <CardContent className='justify-items-stretch'>
                                        <div className='main-sutitle'>{e.content}</div>
                                        <div className='subtitle mt-2 text-[#c8102e] items-end'>{e.name}</div>
                                    </CardContent>
                                </Card>
                                </Grid>)
                        }
                        </Grid>
                    </Box>
                </Stack>
                <div></div>
            </Stack>
        </div>
    );
}

    export default Home;

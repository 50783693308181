import React, { createContext, useContext } from 'react';

const QuestionsContext = createContext();

export const useQuestions = () => useContext(QuestionsContext);

export const QuestionsProvider = ({ children }) => {
    const questions = [
        { id: 'question1', text: 'İdeal iş, kişisel yaşam tarzına ve hobilerine uyum sağlamalıdır.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question2', text: 'Teknolojiye olan yatkınlık, gelecekteki meslek seçimlerinde belirleyici olacaktır.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question3', text: 'Liderlik vasıfları doğuştan gelir ve sonradan geliştirilemez.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question4', text: 'Bir işin prestiji, toplumun o işe verdiği değerle ölçülmelidir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question5', text: 'Başarı, sadece bireysel çabalarla değil, aynı zamanda doğru mentorluk ve ağlarla da sağlanır.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question6', text: 'Karar verme sürecinde duygular, mantık kadar önemlidir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question7', text: 'İş seçiminde etik değerler, maaş ve avantajlardan daha önceliklidir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question8', text: 'Gelecekte, yaratıcı düşünme yeteneği teknik becerilerden daha değerli olacaktır.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question9', text: 'Sürdürülebilirlik ve çevreye duyarlılık, bir işin seçiminde kritik faktörlerdir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question10', text: 'Hayatta büyük riskler almak, büyük ödüller getirir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question11', text: 'Çalışma hayatında dengeyi bulmak için, zaman zaman fedakarlık yapmak zorunludur.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question12', text: 'Bir işi sevmek, o işte ustalaşmak için yeterli bir motivasyondur.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question13', text: 'Akademik başarı, iş hayatında uzun vadeli başarı için yeterli bir gösterge değildir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question14', text: 'İş tatmini, kariyer gelişimi ve yükselmeden daha önemlidir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question15', text: 'Hayatta mutlu olmak için, sürekli olarak konfor alanının dışına çıkmak gereklidir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question16', text: 'Kariyer seçiminde aile ve toplum baskısı, kişisel tercihlerin önüne geçebilir.', context: 'Size en çok uyanı seçiniz' },
        { id: 'question17', text: 'Küreselleşen dünyada, çok kültürlü bir çalışma ortamında bulunmak kritik bir beceridir.', context: 'Size en çok uyanı seçiniz' },
    ];

    return (
        <QuestionsContext.Provider value={questions}>
            {children}
        </QuestionsContext.Provider>
    );
};

import { useLocation, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home/HomePage';
import Navbar from './components/Navbar/Navbar';
import Form from './pages/Form/Form';
import TestPage from './pages/TestPage/TestPage';
import Contact from './pages/Contact/Contact';
import { QuestionsProvider } from './pages/TestPage/QuestionContext'; // Adjust the import path as necessary
import './App.css';

function App() {
  const location = useLocation();
  const isRegisterRoute = location.pathname === '/form';
  return (
    <>
      {!isRegisterRoute && <Navbar />}
      <Routes>
        <Route path="/contact" element={<Contact />} /> 
        <Route path="/" element={<HomePage />} />
        <Route path="/form" element={<Form />} />
        <Route path="/test" element={
          <QuestionsProvider>
            <TestPage />
          </QuestionsProvider>
        } />
      </Routes>
    </>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { Button, Slider, Stack, Alert, Typography, Box, TextField, useMediaQuery, FormControlLabel, Checkbox } from '@mui/material';
import { useQuestions } from './QuestionContext'; 
import { setNewAnswer, updateAnswerWithAI } from '../../utils/answers'; 
import { runGenerativeAI } from '../../utils/generativeAI'; 

import './TestPage.css';

const TestPage = () => {
    const isSmallScreen = useMediaQuery('(max-width:700px)');
    
    const [clearCount, setClearCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [answers, setAnswers] = useState({});
    const [message, setMessage] = useState("");
    const [isAccepted, setAccepted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [aiResponse, setAIResponse] = useState("");
    const [userInfo, setUserInfo] = useState({
        name: '',
        surname: '',
        phoneNumber: ''
    });
    const questions = useQuestions();

    useEffect(() => {
        clearInputs();
    }, [questions]);

    const handleSliderChange = (questionId, value) => {
        setAnswers({
            ...answers,
            [questionId]: value
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({
            ...userInfo,
            [name]: value
        });
    };

    const validateInputs = (name, value) => {
        if(name === "name"){
            if (value.trim().length > 2) return true;
            setErrorMessage("Ad bu kadar kısa olamaz!");
            return false;
        } else if(name === "surname"){
            if (value.trim().length > 1) return true;
            setErrorMessage("Soyisim bu kadar kısa olamaz!");
            return false;
        } else {
            if( value.match('[0-9]*') && (value.length > 10) ) return true;
            setErrorMessage("Telefon numarası geçersiz!");
            return false;
        }
    }
    
    const validateUserInfo = () => {
        return validateInputs("name", userInfo.name) && validateInputs("surname", userInfo.surname) && validateInputs("phoneNumber", userInfo.phoneNumber);
    }

    const clearInputs = () => {
        const tempArr = questions.reduce((acc, question) => {
            acc[question.id] = 2;
            return acc;
        }, {});
        setAnswers(tempArr);
        setUserInfo(
            {
                name: '',
                surname: '',
                phoneNumber: ''
            }
        );
        setAccepted(false);
        setClearCount(clearCount+1);
    }

    const sliderMarks = [
        { value: 0, label: (isSmallScreen ? '' : "Kesinlike") + " Katılmıyorum" },
        { value: 2, label: "Çekimserim" },
        { value: 4, label: (isSmallScreen ? '' : "Kesinlike") + " Katılıyorum" },
    ];

    const valueLabelFormat = (value) => {
        switch (value) {
            case 0: return "Kesinlike Katılmıyorum";
            case 1: return "Katılmıyorum";
            case 2: return "Çekimserim";
            case 3: return "Katılıyorum";
            case 4: return "Kesinlikle Katılıyorum";
            default: return "";
        }
    };

    const handleSendResults = async () => {
        try {
            if (validateUserInfo() && isAccepted) {
                setLoading(true);
                setAIResponse(null);
                if(!loading){
                    const response = await setNewAnswer({ ...userInfo, answers, timestamp: Date.now() });
                    if (response.status === "SUCCESS" && response.docId) {
                        setMessage("Results sent and stored successfully!");
                        const aiResult = await runGenerativeAI(answers);
                        await updateAnswerWithAI(aiResult, response.docId);
                        setAIResponse(aiResult);
                    } else {
                        throw new Error('Error storing results');
                    }
                    setLoading(false);
                    clearInputs();
                }
            } else {
                if (validateUserInfo() && !isAccepted) {
                    setErrorMessage("Aydınlatma ve Rıza metni onaylanmalıdır!");
                }
                setTimeout(() => setErrorMessage(""), 3000);
            }
        } catch (error) {
            setMessage("Something went wrong!!!!");
            setLoading(false);
        }
    };

    return (
        <div className='flex-auto mt-12'>
            <Box sx={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Typography variant="h4" sx={{
                    marginBottom: '20px',
                    fontSize: { xs: '1.5rem', md: '2rem' }
                }}>Test Questions</Typography>
                <Stack spacing={3} sx={{
                    width: '100%',
                    maxWidth: '600px',
                    alignItems: 'center'
                }}
                    key={clearCount}
                >
                    {questions.map(({ id, text, context }) => (
                        <Box key={id} sx={{ width: '100%' }}>
                            <Typography variant="h6" gutterBottom sx={{
                                fontSize: { xs: '1rem', md: '1.25rem' }
                            }}>{text}</Typography>
                            <div className={isSmallScreen ? 'mx-14' : ''}>
                                <Typography variant="body2" color="black" gutterBottom>{context}</Typography>
                                <Slider
                                    defaultValue={2}
                                    step={1}
                                    marks={sliderMarks}
                                    min={0}
                                    max={4}
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={valueLabelFormat}
                                    onChangeCommitted={(e, value) => handleSliderChange(id, value)}
                                    sx={{
                                        marginBottom: isSmallScreen ? '20px' : '40px',
                                        '& .MuiSlider-markLabel': { transform: 'translateX(-50%)' },
                                        '& .MuiSlider-thumb': {
                                            backgroundColor: '#c8102e',
                                            border: '1px solid currentColor',
                                            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                                                boxShadow: '0 0 0 8px rgba(111, 110, 110, 0.15)',
                                            },
                                            '&::before': {
                                                display: 'none',
                                            },
                                        },
                                        color: '#c8102e'
                                    }}
                                />
                            </div>
                        </Box>
                    ))}
                    <TextField
                        label="Ad"
                        name="name"
                        value={userInfo.name}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Soyad"
                        name="surname"
                        value={userInfo.surname}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        style={{
                            '& .MuiOutlinedInput-root': {  // - The Input-root, inside the TextField-root
                                '& fieldset': {            // - The <fieldset> inside the Input-root
                                    borderColor: 'pink',   // - Set the Input border
                                },
                                '&:hover fieldset': {
                                    borderColor: 'yellow', // - Set the Input border when parent has :hover
                                },
                                '&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
                                    borderColor: 'green',
                                },
                            },
                        }}
                    />
                    <TextField
                        label="Telefon Numarası"
                        name="phoneNumber"
                        autoComplete="phone"
                        variant="outlined"
                        placeholder="05551112233"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ inputMode: 'numeric' }}
                        value={userInfo.phoneNumber}
                        onChange={handleInputChange}
                        fullWidth   
                    />
                    <Typography variant="body2" color="black" gutterBottom>
                    Telefon numarası, test sonuçlarınızın, uzmanlarımız tarafından değerlendirilip size geri dönüş sağlanabilmesi için istenmektedir. </Typography>
                    <div className='kvkk'>
                    <FormControlLabel control={
                        <Checkbox checked={isAccepted} onChange={(e)=>setAccepted(e.target.checked)} sx={{'&.Mui-checked': {color: '#c8102e'}}} />} 
                        label={
                            <div className='kvkk-accepted-text'>
                                <a className='kvkk-text' href='/kvkk.html' target="_blank" rel="noopener noreferrer">Aydınlatma ve Rıza</a> metnini okudum, onaylıyorum.
                            </div>}/>
                    </div>  
                    <Button
                        variant="contained"
                        onClick={handleSendResults}
                        sx={{
                            width: '100%',
                            backgroundColor: '#c8102e',
                            '&:hover': {
                                backgroundColor: '#c8102e',
                            },
                            '&:active': {
                                backgroundColor: '#c8102e',
                            },
                            '&:focus': {
                                backgroundColor: '#c8102e',
                            },
                            '&:visited': {
                                backgroundColor: '#c8102e',
                            }
                        }}
                    >
                        {!loading ? "Gönder" : "YÜKLENİYOR..."}
                    </Button>
                    {!aiResponse && message && <Alert severity={message.includes("success") ? "success" : "error"} sx={{ marginTop: '20px', width: '100%' }}>{message}</Alert>}
                    {!message && errorMessage.length > 0 ? <Alert severity="error">{errorMessage}</Alert> : <></>}
                    {aiResponse && <Alert severity="info" sx={{ marginTop: '20px', width: '100%' }}>
                        {aiResponse.split('\n').map((line, index) => (
                        <div className="ai-response-text" key={index}>
                            {
                                line.split('**').map((part, i) => (
                                <span key={i} className={i % 2 === 0 ? 'ai-text' : 'ai-bold-text'}>{part}</span>))
                            }
                        </div>))}
                    </Alert>}
                </Stack>
            </Box>
        </div>
    );
};

export default TestPage;

import './Form.css';

import React, {useState, useEffect} from 'react';
import {Stack, Button, TextField, MenuItem, Select, Alert, Modal, FormControlLabel, Checkbox} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {CorporateFare,ArrowBackIos, School, AccountCircle, Email} from '@mui/icons-material';
import { isValidDateValue } from '@testing-library/user-event/dist/utils';

export function Form() {
    const ERROR_MESSAGE = "Bir şeyler ters gitti :/";

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [grade, setGrade] = useState("Hazırlık")
    const [university, setUniversity] = useState("")
    const [department, setDepartment] = useState("")
    const [isAccepted, setAccepted] = useState(false)


    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [qr, setQR] = useState("");

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
    }

    const clear = () => {
        setEmail("");
        setName("");
        setPhone("");
        setGrade("");
        setUniversity("");
        setGrade("Hazırlık");
        setUniversity("");
        setDepartment("");
    }

    const validateValues = () => {
        if (email.trim().length == 0) return false;
        if (name.trim().length == 0) return false;
        if (phone.trim().length == 0) return false;
        if (university.trim().length == 0) return false;
        if (department.trim().length == 0) return false;
        return true;
    }

    const handleRegister = (e) => {
        setMessage("");
        setQR("");
        e.preventDefault();
        if (!validateEmail(email)) {
            setQR("");
            setMessage("Geçersiz email!");
            setTimeout(() => setMessage(""), 3000);
        }
        else{
            setLoading(true);
            if (!loading) {
                //TODO send data to database
            }
        }
    }
    return (
        <div className='register-div'>
            <div className='register'>
                <p className="register-text">HACETTEPE KARİYER FUARI'24</p>
                <div style={{display: 'flex', flexDirection: 'column', width: '70%'}}>
                    <FormControl variant="standard" style={{width: '100%'}}>
                        <TextField id="name" variant="standard" placeholder="İsim Soyisim" label="İsim Soyisim"
                                   value={name} onChange={(e) => setName(e.target.value)} sx={{mb: '10px',}}
                                   color="error"
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">
                                               <AccountCircle style={{ fontSize: 20 }}/>
                                           </InputAdornment>
                                       ),
                                   }}/>
                        <TextField id="email" variant="standard" placeholder='Mail' label="Email"
                                   value={email} onChange={(e) => setEmail(e.target.value)} sx={{mb: '10px',}}
                                   color="error"
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">
                                               <EmailIcon style={{ fontSize: 20 }}/>
                                           </InputAdornment>
                                       ),
                                   }}/>
                        <TextField id="phone" variant="standard" label="Telefon Numarası" placeholder='05xxxxxxxxx' autoComplete="phone"
                                   value={phone} onChange={(e) => setPhone(e.target.value)} sx={{mb: '10px',}}
                                   color="error"
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">
                                               <LocalPhoneIcon style={{ fontSize: 20 }}/>
                                           </InputAdornment>
                                       ),
                                   }}/>
                    </FormControl>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '70%'}}>
                    <FormControl variant="standard" style={{width: '100%'}}>
                        <TextField id="university" variant="standard" placeholder='Hacettepe Üniversitesi' label="Üniversite"
                                   value={university} onChange={(e) => setUniversity(e.target.value)}
                                   sx={{mb: '10px',}}
                                   color="error"
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">
                                               <School style={{ fontSize: 20 }}/>
                                           </InputAdornment>
                                       ),
                                   }}/>
                        <TextField id="department" variant="standard" placeholder='Bilgisayar Mühendisliği' label="Bölüm"
                                   value={department} onChange={(e) => setDepartment(e.target.value)}
                                   sx={{mb: '20px',}}
                                   color="error"
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">
                                               <CorporateFare style={{ fontSize: 20 }}/>
                                           </InputAdornment>
                                       ),
                                   }}/>
                    </FormControl>
                    <div className='grade' style={{width: '100%'}}>
                        <FormControl variant="standard" style={{width: '100%'}}>
                            <Select labelId="grade" id="simple-select" label="Sınıf"
                                    value={grade} onChange={(e) => setGrade(e.target.value)}
                                    color="error">
                                <MenuItem value={"Hazırlık"}>Hazırlık</MenuItem>
                                <MenuItem value={"1. Sınıf"}>1. Sınıf</MenuItem>
                                <MenuItem value={"2. Sınıf"}>2. Sınıf</MenuItem>
                                <MenuItem value={"3. Sınıf"}>3. Sınıf</MenuItem>
                                <MenuItem value={"4. Sınıf"}>4. Sınıf</MenuItem>
                                <MenuItem value={"Yüksek Lisans"}>Yüksek Lisans</MenuItem>
                                <MenuItem value={"Doktora"}>Doktora</MenuItem>
                                <MenuItem value={"Mezun"}>Mezun</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='kvkk'>
                    <FormControlLabel control={
                        <Checkbox checked={isAccepted} onChange={(e)=>setAccepted(e.target.checked)} sx={{'&.Mui-checked': {color: '#9d0000'}}} />} 
                        label={
                            <div className='kvkk-accepted-text'>
                                <a className='kvkk-text' href='/kvkk.html' target="_blank" rel="noopener noreferrer">Aydınlatma ve Rıza</a> metnini okudum, onaylıyorum.
                            </div>}/>
                    </div>  
                </div>
                <div className='button'>
                    <Button disabled={!isAccepted || !validateValues()} onClick={handleRegister}
                            style={{color: '#ffffff', backgroundColor: isAccepted && validateValues() ? '#9d0000' : '#adadaa', borderRadius:'20px', fontFamily:'Poppins' }}>{!loading ? "Kayıt Ol" : "Yükleniyor"}</Button>
                </div>
                <div className='button'>
                    <Button href="/"
                            startIcon={<ArrowBackIos/>}
                            style={{color: '#ffffff',fontFamily:'Poppins', position:'absolute',top:0, left:0,marginLeft:'10px' }}>Ana Sayfaya Dön</Button>
                </div>
            </div>

            {message.length > 0 && qr.length == 0 ? <Alert severity="error">{message}</Alert> : <></>}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus={true}
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div className='modal-qr'>
                    <Stack justifyContent="center" alignItems="center" spacing={4}>
                        <img src={qr}/>
                        <Alert severity="success">{message}</Alert>
                    </Stack>
                </div>
            </Modal>

        </div>
    )
}

export default Form;
import React, { useState } from 'react';
import { Stack, Button, TextField, Typography, Divider, useMediaQuery } from '@mui/material';
import { YouTube, Instagram, Mail } from '@mui/icons-material';
import './Contact.css';
import { setNewFeedBack } from '../../utils/feedback';

function Contact() {
    const isSmallScreen = useMediaQuery('(max-width:700px)');
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');
    
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        validateEmail(event.target.value);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Geçerli bir e-posta adresi giriniz.');
        } else {
            setError('');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (!error && name && content && !loading) {
            // Form submission logic here
            const response = await setNewFeedBack(name, email, content);
            if(response.status === 'SUCCESS'){
                console.log('Form submitted successfully!');
                alert('Form başarıyla gönderildi.');
            }else{
                alert('Bi hata oluştu :/');
            }
        } else {
            alert(loading ? 'Gönderme işleminin tamamlanmasını bekleyiniz!': 'Tüm alanları doldurunuz!');
            console.log('Please fix the errors before submitting the form.');
        }
        setLoading(false);
    };

    return (
        <div className="contact-page">
            <div className="contact-content">
                <Stack spacing={2}>
                    <Typography variant="h4" component="h2" className="contact-title">
                        İletişim
                    </Typography>
                    <Divider className="divider"/>
                    <Typography variant="h6" component="h3" className="contact-subtitle">
                        Ataberk Musaoğlu
                    </Typography>
                    <Typography variant="body1" className="contact-description">
                    YKS tercihlerinde endişe yok, çünkü hocam ODTÜ'lü!
                    </Typography>
                    <Stack direction={isSmallScreen ? 'column' : "row"} spacing={2} justifyContent="center" alignItems="center" className="contact-buttons">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<YouTube />}
                            href="https://www.youtube.com/c/AtaberkMusao%C4%9Fluyks"
                            className="contact-button"
                            >
                            YouTube
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<Instagram />}
                            href="https://instagram.com/ataberk.musaoglu"
                            className="contact-button"
                            >
                            Instagram
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Mail />}
                            href="mailto:ataberk.musaoglu@gmail.com"
                            className="contact-button"
                            >       
                            E-Mail
                        </Button>
                    </Stack>
                    <Divider className="divider space-above-title"/>
                    <Typography variant="h5" component="h3" className="form-title space-above-title">
                        Geri Bildirim Formu
                    </Typography>
                    <form className="feedback-form space-above" onSubmit={handleSubmit}>
                        <TextField
                            label="Adınız"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            className="feedback-form-field"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            />
                        <TextField
                            label="E-posta"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            className="feedback-form-field"
                            value={email}
                            onChange={handleEmailChange}
                            error={!!error}
                            helperText={error}
                            />
                        <TextField
                            label="Mesajınız"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                            className="feedback-form-field"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            />
                        <Button
                            sx={{ '&:hover': {backgroundColor: 'red',boxShadow: 'none',}}}
                            variant="contained"
                            type="submit"
                            className="feedback-form-button"
                            >
                            {loading ? 'Yükleniyor...' : 'Gönder'}
                        </Button>
                    </form>
                </Stack>
            </div>
        </div>
    );
}

export default Contact;

import { collection, getDocs, getFirestore, query, orderBy, limit, getDoc, doc, addDoc, updateDoc, where } from "firebase/firestore";
import app from '../firebase';
import { Timestamp } from 'firebase/firestore';

const db = getFirestore(app);
const collectionName = "answers";
const ERROR_MESSAGE = "ERROR";

export function convertTimestamp(timestamp) {
    if (timestamp === undefined) {
        return "";
    }

    let date = new Date(timestamp);
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    let yyyy = date.getFullYear();

    if (String(mm).length < 2) {
        mm = '0' + mm;
    }

    if (String(dd).length < 2) {
        dd = '0' + dd;
    }

    date = dd + '/' + mm + '/' + yyyy;
    return date;
}

export const getLastAnswers = async () => {
    try {
        const docRef = collection(db, collectionName);
        const queryParam = query(docRef, orderBy('timestamp', 'desc'), limit(5));
        const querySnapshot = await getDocs(queryParam);
        if (!querySnapshot.empty) {
            return querySnapshot.docs.map(doc => {
                return {
                    ...doc.data(),
                    id: doc.id,
                    timestamp: convertTimestamp(doc.data().timestamp),
                }
            });
        } else {
            return [];
        }
    } catch (error) {
        console.error(`Error getting documents:`, error);
        return [];
    }
}

export const fetchAnswersFromFirestore = async (docId) => {
    const docRef = doc(db, "answers", docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data().answers;
    } else {
        console.log("No such document!");
        return null;
    }
}

export const getAllAnswers = async () => {
    try {
        const docRef = collection(db, collectionName);
        const queryParam = query(docRef, orderBy('timestamp', 'desc'));
        const querySnapshot = await getDocs(queryParam);
        if (!querySnapshot.empty) {
            return querySnapshot.docs.map(doc => {
                return {
                    ...doc.data(),
                    id: doc.id,
                    timestamp: convertTimestamp(doc.data().timestamp),
                }
            });
        } else {
            return [];
        }
    } catch (error) {
        console.error(`Error getting documents:`, error);
        return [];
    }
}

export const setNewAnswer = async (data) => {
    try {
        const phoneID = await getAnswerWithPhone(data.phoneNumber);
        console.log("phoneID: " + phoneID);
        console.log(!phoneID);
        let docRef;
        if (phoneID) {
            await updateDoc(doc(db, "answers", phoneID), data);
            return { status: "SUCCESS", docId: phoneID };
        } else {
            console.log("sjsj");
            docRef = await addDoc(collection(db, "answers"), data);
            return { status: "SUCCESS", docId: docRef.id };
        }
    } catch (error) {
        console.error("Error adding document: ", error);
        return { status: "ERROR", error: error.message };
    }
};

export const updateAnswerWithAI = async (ai, id) => {
    try {
        const docRef = doc(db, collectionName, id);
        await updateDoc(docRef, { aiResponse: ai });
    } catch (error) {
        console.log(error);
    }
}

export const getAnswerWithPhone = async (phoneNumber) => {
    try {
        const docRef = collection(db, collectionName);
        const queryParam = query(docRef, where('phoneNumber', '==', phoneNumber), limit(1));
        const querySnapshot = await getDocs(queryParam);
        if (!querySnapshot.empty) {
            return querySnapshot.docs[0].id;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting document with phone: ", error);
        return null;
    }
}